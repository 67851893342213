<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:02
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-06 18:05:21
-->
<template>
  <div class="">
    <a-input-group compact>
      <a-input style="width: 80%" v-model="name" disabled placeholder="请查询经销商"></a-input>
      <a-button style="width: 20%; margin: 0" @click="toSearch(3)" icon="search"></a-button>
    </a-input-group>

    <a-modal
      :maskClosable="false"
      title="查找经销商"
      style="top: 8px"
      :width="1000"
      v-model="visible"
      :footer="null"
      @cancel="closeModal"
    >
      <a-row :gutter="12">
<!--        <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--          <a-form-model-item :autoLink="false" ref="test">-->
<!--            <a-cascader-->
<!--              v-model="dealerId"-->
<!--              @change="onDealerChange"-->
<!--              :options="dealerList"-->
<!--              :field-names="{ label: 'title', value: 'id', children: 'children' }"-->
<!--              placeholder="请选择大区/事务所"-->
<!--            />-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item>
            <a-input-search v-model="dealerName" placeholder="经销商名称" @search="toSearch">
              <a-button slot="enterButton">搜索</a-button>
            </a-input-search>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item>
            <a-input-search v-model="dealerCode" placeholder="经销商编码" @search="toSearch">
              <a-button slot="enterButton">搜索</a-button>
            </a-input-search>
          </a-form-model-item>
        </a-col>
      </a-row>

      <div class="table">
        <a-table
          size="small"
          :data-source="tableData"
          bordered
          rowKey="id"
          :pagination="page"
          @change="
            (p) => {
              page = p
              toSearch(2)
            }
          "
          :customRow="changeTableRow"
        >
<!--          <a-table-column title="大区/事务所"  data-index="area" align="">-->
<!--            <template slot-scope="text, record">{{ record.area }}_{{ record.firmName }}</template>-->
<!--          </a-table-column>-->
          <a-table-column title="经销商名称" :width="150"  data-index="name" align=""> </a-table-column>
          <a-table-column title="经销商编码" :width="120"  data-index="dealerCode" align="center"> </a-table-column>
          <a-table-column title="联系人" data-index="contacts" :width="120" align="center"> </a-table-column>
          <a-table-column title="联系电话" data-index="phone" :width="150" align="center"> </a-table-column>
          <a-table-column title="地址" data-index="" align="left" :width="450">
            <template slot-scope="text, record">{{ record.province }}{{ record.city }}{{ record.county }}{{ record.address }}</template>
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
const qs = require('qs')
export default {
  data() {
    return {
      visible: false,
      name: '',
      dealerName: '',
      dealerCode: '',
      tableData: [],
      cusCode: '',
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        size: 'small',
      },
      dealerId: [],
      firmId: '',
      dealerList: [],
    }
  },
  props: ['value', 'officeId'],
  watch: {
    value(newVal) {
      this.name = newVal
    },
  },
  methods: {
    closeModal() {
      this.dealerName = '', 
      this.cusCode = '', 
      this.dealerCode = ''
    },

    onDealerChange(val, data) {
      this.$refs.test.onFieldChange()
      this.firmId = val[1]
      this.toSearch(1)
    },

    toSearch(type) {
      // this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      //   // 获取大区事务所经销商数据
      //   this.dealerList = res.body
      // })
      if(type == 3){
        this.dealerName = '', 
        this.cusCode = '', 
        this.dealerCode = ''
      }
      this.visible = true
      const postData = Object.assign(
        {},
        {
          firmId: !this.officeId ? this.firmId : this.officeId,
          name: this.dealerName,
          dealerCode: this.dealerCode,
          pageNumber: type == 2 ? this.page.current : (this.page.current = 1),
          pageSize: this.page.pageSize,
          dealerClassify:1,
          verify: 1,
        }
      )

      this.axios
        .get(`/api/dealer/dealer/dealerInfo/listForCurrOrderFirm?${qs.stringify(postData)}`)
        .then((res) => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
        .catch((err) => {})
    },
    changeTable(pagination) {
      this.page = pagination
      this.toSearch(2)
    },
    changeTableRow(record) {
      return {
        on: {
          dblclick: (e) => {
            this.visible = false
            this.$emit('update:value', record.customerName)
            this.$emit('select', record)
          },
        },
      }
    },
  },
  created() {
    if (this.value) {
      this.name = this.value
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
